import React, { useEffect } from 'react';
import './TrayectorySection.css';
import CountUp from 'react-countup';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Divider } from '@chakra-ui/react';

function TrayectorySection() {

    return (
        <>
            <body className="advantage-mark">
                <br />
                <h1 className='mark-trayectory'>Trayectoria de la Marca</h1>
                <Divider className='divisor' /> <h5 className='rasgos'>Integridad, Pragmatismo e Innovación</h5>
                
                


                <div className='container'>
                    <div className="row grilla">

                        <div className="col-lg-4 first">
                            <div className="count-up-wrapper top">
                                {/* <span id="count-up-container-top" className='size-count'>0</span> */}
                                <CountUp className='size-count' end={1959} duration={8}/>
                                <span className="additional-info">Empresa fundada desde:</span><br /><br />
                            </div>
                        </div>


                        <div className="col-lg-4 second">
                            <div className="count-up-wrapper middler">
                                <span className="additional-info">Calidad reconocida por el mundo desde:</span><br /><br />
                                {/* <span id="count-up-container-middler" className='size-count'>0</span> */}
                                <CountUp className='size-count' end={2014} duration={8}/>

                            </div>
                        </div>


                        <div className="col-lg-4 third">
                            <div className="count-up-wrapper bottom">
                                <span className="additional-info">Trabajando con Cauchos La Mundial, C.A. desde hace:</span><br /><br /><br />
                                {/* <span id="count-up-container-bottom" className='size-count'>0 </span>  */}
                                <CountUp className='size-count' end={10} duration={12}/> <br/> <h2 className='year'> años</h2>

                            </div>
                        </div>
                    </div>
                </div>
            </body>
        </>
    );
}

export default TrayectorySection;