import React from "react";
import './DescriptionSection.css';
import { Card } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoneyBillWheat, faRankingStar, faBolt, faShekelSign, faCodeBranch } from '@fortawesome/free-solid-svg-icons';


function DescriptionSection() {

    return (
        <>
            <body className="description-zone">
                <div className="description-mark">
                    <h6>NUESTRA MARCA</h6>
                    <h1 className="description">ACERCA DE NOSOTROS</h1>
                    <div className="row custom_column">

                        <div className="col-lg-2 col-sm-2 col-md-2"></div>

                        {/* <div className="col-lg-2 col-sm-2 col-md-2">
                            <div className="icon_box_1">
                                <div className="flipper">
                                    <Card className="front">
                                        <FontAwesomeIcon className="front-icon" icon={faMoneyBillWheat} />
                                        <div className="front-header">PATROCINADORES DE RÁPIDOS Y FURIOSOS</div>
                                    </Card>
                                </div>
                            </div>
                        </div> */}

                        <div className="col-lg-2 col-sm-2 col-md-2">
                            <div className="icon_box_1">
                                <div className="flipper">
                                    <Card className="front">
                                        <FontAwesomeIcon className="front-icon" icon={faRankingStar} />
                                        <div className="front-header">SOMOS LA MARCA #1 EN TAIWAN</div>
                                    </Card>
                                </div>
                            </div>
                        </div>


                        <div className="col-lg-2 col-sm-2 col-md-2">
                            <div className="icon_box_1">
                                <div className="flipper">
                                    <Card className="front">
                                        <FontAwesomeIcon className="front-icon" icon={faBolt} />
                                        <div className="front-header">ESPECIALISTAS EN CAUCHOS DE VELOCIDAD</div>
                                    </Card>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-2 col-sm-2 col-md-2">
                            <div className="icon_box_1">
                                <div className="flipper">
                                    <Card className="front" >
                                        <FontAwesomeIcon className="front-icon" icon={faShekelSign} />
                                        <div className="front-header">INNOVACIÓN, DISEÑO Y ELEGANCIA</div>
                                    </Card>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-2 col-sm-2 col-md-2">
                            <div className="icon_box_1">
                                <div className="flipper">
                                    <Card className="front" >
                                        <FontAwesomeIcon className="front-icon" icon={faCodeBranch} />
                                        <div className="front-header">ES UNA DIVISIÓN DE LA MARCA YOKOHAMA</div>
                                    </Card>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-2 col-sm-2 col-md-2"></div>

                    </div>
                </div >
            </body >
        </>
    );
}

export default DescriptionSection;