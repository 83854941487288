import React from "react";
import './Aboutthetire.css';
import Preloading from "./sections/Preloading";
import 'bootstrap/dist/css/bootstrap.min.css';

function Aboutthetire() {

    return (
        <>
            <Preloading />
            <body className="tire-about">

                {/* Banner */}
                <div className="banner">
                    <section className="banner_box">
                        <div className="banner_red_bg"></div>
                        <div className="banner_pic banner_pic_knowledge_detail"></div>
                        <div className="banner_black_cover"></div>
                        <div className="main">
                            <div className="banner_title">
                                <h2 className="title_l">Conocimiento del neumático</h2>
                                <div className="d-pad-flex">
                                    <span className="red_line"></span>
                                    <h3 className="bold"></h3>
                                </div>
                            </div>
                        </div>
                    </section>

                </div>

                {/* Marca de talón */}
                <section className="heel-mark">
                    <div className="main">
                        <div className="heel-mark-detail spacing">
                            <h4 className="double_line_title title_m marb_50 marb_mb_30 white italic">
                                Marca de talón</h4>

                            <div className="row row-mb clearfix">
                                <div className="col-12 col-mb-12 fleft align_center">
                                    <div className="tire_data_main">
                                        <div className="tire_data_pic"></div>
                                        <div className="tire_data_bg"></div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
            </body>
        </>
    );
}

export default Aboutthetire;