import React from "react";
import Accordion from 'react-bootstrap/Accordion';
import '../commerces/css commerces/Comercio.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faPhone, faLocationArrow } from '@fortawesome/free-solid-svg-icons';
import Preloading from "../sections/Preloading";
import Footer from '../../Footer';

function Comercio_aragua() {
    return (
        <>
            <Preloading />
            <body class="comerce" >
                <div >
                    <center>
                        <div class="acordion-comercio">
                            <br />
                            <h2>Redes de Distribución de Aragua</h2>
                            <br /><br /><br /><br />
                            <Accordion style={{ width: '55%' }}>

                                <Accordion.Item eventKey="0">
                                    <Accordion.Header class="acordion-header">DISTRIBUIDORA DLT, C.A.</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faLocationArrow} /> AV. SANTA MARIA CASA N° 104 BARRIO LA COROMOTO, MARACAY EDO. ARAGUA<br />
                                        <FontAwesomeIcon icon={faInstagram} /> <a className="contacts" href="https://www.instagram.com/caucheradlt/" target="_blank">@caucheradlt</a> <br />
                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel:+584140520344" target="_blank">+58 414-0520344</a><br /><br />

                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="1">
                                    <Accordion.Header class="acordion-header">PROCAUCHOS S.S, C.A.</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faInstagram} /> <a className="contacts" href="https://www.instagram.com/procauchosss/" target="_blank">@procauchosss</a> <br />
                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel:+584144948771" target="_blank">+58 414-4948771</a><br /><br />
                                        <div class="ubic">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d1651.8123551518966!2d-67.49689468930202!3d10.04208907240155!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTDCsDAyJzMxLjUiTiA2N8KwMjknNDcuOSJX!5e0!3m2!1ses!2sve!4v1687900933324!5m2!1ses!2sve" width="400" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>


                                {/* <Accordion.Item eventKey="0">
                                    <Accordion.Header class="acordion-header">CAUCHOS Y AUTO SERVICIOS JUMBO TIRE´S, C.A.</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faLocationArrow} /> AV. ARAGUA DIAGONAL AUTOPISTA REGIONAL<br />
                                        <FontAwesomeIcon icon={faInstagram} /> <a className="contacts" href="https://www.instagram.com/jumbo.tires/" target="_blank">@jumbo.tires</a> <br />
                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel:+584145906012" target="_blank">+58 414-5906012</a><br /><br />
                                        <div class="ubic">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1650.8576373536919!2d-67.58531837770927!3d10.22740342201944!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e803cc6636c5c93%3A0xa742f4a15c1d973d!2sJumbo%20Tires%20ca.%20PIRELLI!5e0!3m2!1ses!2sve!4v1687900415801!5m2!1ses!2sve" width="400" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="1">
                                    <Accordion.Header class="acordion-header">POWER CAUCHO, C.A.</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faInstagram} /> <a className="contacts" href="https://www.instagram.com/powercaucho/" target="_blank">@powercaucho</a> <br />
                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel:+584243377804" target="_blank">+58 424-3377804</a><br /><br />
                                        <div class="ubic">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1651.0341083084486!2d-67.46251383696799!3d10.19340090048051!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e80214aadb184b3%3A0xcc9883271a7c1728!2sPower%20caucho%20C.A!5e0!3m2!1sen!2sve!4v1687900574559!5m2!1sen!2sve" width="400" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="2">
                                    <Accordion.Header class="acordion-header">GRUPO TURAGUA PRIX, C.A.</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faInstagram} /> <a className="contacts" href="https://www.instagram.com/grupoturaguaprix/" target="_blank">@grupoturaguaprix</a> <br />
                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel:+584243055424" target="_blank">+58 424-3055424</a><br /><br />
                                    </Accordion.Body>
                                </Accordion.Item>



                                <Accordion.Item eventKey="4">
                                    <Accordion.Header class="acordion-header">CAUCHOS LÍDER LOS SAMANES, C.A.</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faLocationArrow} /> AV. PRINCIPAL LOS SAMANES, C.C. SUPER LÍDER LOS SAMANES<br />
                                        <FontAwesomeIcon icon={faInstagram} /> <a className="contacts" href="https://www.instagram.com/cauchoslider/" target="_blank">@cauchoslider</a> <br />
                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel:+584121367278" target="_blank">+58 412-1367278</a><br /><br />
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="5">
                                    <Accordion.Header class="acordion-header">CAUCHOS DAKAR, C.A.</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faLocationArrow} /> AV. RAMÓN NARVAEZ FRENTE AL PARQUE STOS MICHELENA AL LADO DE TEALCA, MARACAY<br />
                                        <FontAwesomeIcon icon={faInstagram} /> <a className="contacts" href="https://www.instagram.com/zone4x4vzla/" target="_blank">@zone4x4vzla</a> <br />
                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel:+584144452414" target="_blank">+58 414-4452414</a><br /><br />
                                        <div class="ubic">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2776.115779367829!2d-67.61755766501405!3d10.259923077022627!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e803b7f3b1023b1%3A0x6ff47ad719d2a349!2sCauchos%20Dakar!5e0!3m2!1sen!2sve!4v1685560536052!5m2!1sen!2sve" width="400" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="6">
                                    <Accordion.Header class="acordion-header">AUTOSERVICIOS LA CARRERA, C.A.</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faLocationArrow} /> CALLE GUAICAIPURO ENTRE CALLE TIUNA Y CALLE MARA CASA NRO. 107 BARRIO CESAR RODRIGUEZ PALENCIA TURMERO ZONA POSTAL 2115.<br />
                                        <FontAwesomeIcon icon={faInstagram} /> <a className="contacts" href="https://www.instagram.com/autoservicioslacarrera/" target="_blank">@autoservicioslacarrera</a> <br />
                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel:+584124613554" target="_blank">+58 412-4613554</a><br /><br />
                                        <div class="ubic">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d981.6064809479758!2d-67.53029066228875!3d10.227190918736534!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e80238037d53797%3A0x907323190f1abf7e!2sAutoservicios%20La%20Carrera!5e0!3m2!1sen!2sve!4v1687901402569!5m2!1sen!2sve" width="400" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item> */}

                            </Accordion>
                            <br /><br /><br /><br />
                        </div>
                    </center>
                </div>
            </body>
            <Footer />
        </>
    );
}

export default Comercio_aragua;