import React from "react";
import Accordion from 'react-bootstrap/Accordion';
import '../commerces/css commerces/Comercio.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faPhone, faLocationArrow } from '@fortawesome/free-solid-svg-icons';
import Preloading from "../sections/Preloading";
import Footer from '../../Footer';

function Comercio_lara() {
    return (
        <>
            <Preloading />
            <body class="comerce" >
                <div >
                    <center>
                        <div class="acordion-comercio">
                            <br></br>
                            <h2>Redes de Distribución de Lara</h2>
                            <br></br><br></br>
                            <Accordion style={{ width: '55%' }}>

                                <Accordion.Item eventKey="0">
                                    <Accordion.Header class="acordion-header">RALI CAUCHOS (PRINCIPAL), C.A.</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faLocationArrow} /> AV. LIBERTADOR CON AVENIDA MORAN<br />
                                        <FontAwesomeIcon icon={faInstagram} /> <a className="contacts" href="https://www.instagram.com/ralicauchos/" target="_blank">@ralicauchos</a><br />
                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel:+584145144649" target="_blank">+58 414-5144649</a><br /><br />
                                        <div class="ubic">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d2335.7305682659658!2d-69.30391489529511!3d10.081468226136165!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTDCsDA0JzUzLjMiTiA2OcKwMTgnMDkuOCJX!5e0!3m2!1ses!2sve!4v1687879878607!5m2!1ses!2sve" width="400" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="1">
                                    <Accordion.Header class="acordion-header">RALI CAUCHOS (SUCURSAL), C.A.</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faLocationArrow} /> AV. LIBERTADOR CON AVENIDAD MORAN<br />
                                        <FontAwesomeIcon icon={faInstagram} /> <a className="contacts" href="https://www.instagram.com/ralicauchos/" target="_blank">@ralicauchos</a><br />
                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel:+584145144649" target="_blank">+58 414-5144649</a><br /><br />
                                        <div class="ubic">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d2335.9780849148274!2d-69.39563398951125!3d10.047260959423522!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTDCsDAyJzUwLjEiTiA2OcKwMjMnNDAuNCJX!5e0!3m2!1ses!2sve!4v1687879982647!5m2!1ses!2sve" width="400" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="2">
                                    <Accordion.Header class="acordion-header">RODDAR MULTISERVICIOS, C.A.</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faLocationArrow} /> AV. LIBERTADOR ESQUINA CALLE 48 LOCAL NRO. S/N SECOTR CENTRO BARQUISIMETO LARA ZONA POSTAL 3001<br />
                                        <FontAwesomeIcon icon={faInstagram} /> <a className="contacts" href="https://www.instagram.com/cauchosroddar/" target="_blank">@cauchosroddar</a><br />
                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel:+584145138206" target="_blank">+58 414-5138206</a><br /><br />
                                        <div class="ubic">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d1167.8826358323702!2d-69.33989006808696!3d10.076679078221908!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTDCsDA0JzM1LjgiTiA2OcKwMjAnMjIuNiJX!5e0!3m2!1ses!2sve!4v1687880222269!5m2!1ses!2sve" width="400" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="3">
                                    <Accordion.Header class="acordion-header">NEUMÁTICOS TRANSALCA, C.A.</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faInstagram} /> <a className="contacts" href="https://www.instagram.com/neumaticostransalcabqto/" target="_blank">@neumaticostransalcabqto</a><br />
                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel:+584245059985" target="_blank">+58 424-5059985</a><br /><br />
                                        <div class="ubic">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2335.7356446969056!2d-69.33444633243336!3d10.080767807726303!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e87675113483e07%3A0x1a4d24f033934e91!2sNeum%C3%A1ticos%20Transalca%2C%20C.A%20(sede%20la%2042)!5e0!3m2!1ses!2sve!4v1689259411998!5m2!1ses!2sve" width="400" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="4">
                                    <Accordion.Header class="acordion-header">SUPER CAUCHOS MERCABAR, S.A.</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faInstagram} /> <a className="contacts" href="https://www.instagram.com/gruposupercauchos/" target="_blank">@gruposupercauchos</a><br />
                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel:+584125196017" target="_blank">+58 412-5196017</a><br /><br />
                                        <div class="ubic">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2777.7322232122488!2d-69.39176956056947!3d10.07394598692847!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e876504996b79a9%3A0x3dc8c613aab389e0!2sGrupo%20Super%20Cauchos!5e0!3m2!1ses!2sve!4v1689259593979!5m2!1ses!2sve" width="400" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="5">
                                    <Accordion.Header class="acordion-header">VITOCAUCHOS, C.A.</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faInstagram} /> SIN INFORMACIÓN<br />
                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel:+584123044618" target="_blank">+58 412-3044618</a><br /><br />
                                        <div class="ubic">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1388.9803036923495!2d-69.39586057606155!3d10.047395025505699!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e8765d85641173d%3A0x215b6b8a54cc9bc6!2sVito%20Cauchos!5e0!3m2!1ses!2sve!4v1689259774619!5m2!1ses!2sve" width="400" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                            </Accordion>
                            <br /><br /><br /><br />
                            <br /><br /><br />
                        </div>
                    </center>
                </div>
            </body>
            <Footer />
        </>
    );
}

export default Comercio_lara;