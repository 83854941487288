import React, { useEffect, useState } from 'react';
import './Navbar.css';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import 'bootstrap/dist/css/bootstrap.min.css';

function Navbar() {

    const [navClasses, setNavClasses] = useState(['navbar', 'bg-transparent', 'fixed-top']);

    useEffect(() => {


        const handleScroll = () => {
            if (window.pageYOffset > 100) {
                setNavClasses(['bg-dark', 'shadow', 'navbar', 'navbar-principal', 'navbar-expand-lg', 'navbar-light', 'bg-light', 'bg-transparent', 'fixed-top']);
            } else {
                setNavClasses(['navbar', 'navbar-principal', 'bg-transparent', 'fixed-top']);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);





    // funcion del boton de version mobile

    const [click, setClick] = useState(false);
    const [button, setButton] = useState(true);

    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);

    const showButton = () => {
        if (window.innerWidth <= 960) {
            setButton(false);
        } else {
            setButton(true);
        }
    };

    useEffect(() => {
        showButton();
    }, []);

    window.addEventListener('resize', showButton);

    return (
        <>
            <body className='navbar-principal'>
                {/* navbar */}
                <nav class={navClasses.join(' ')}>
                    <div class="container">
                        <a class="navbar-brand" href="/"><img src='images/logo-nankang.webp' /></a>
                        <div className='menu-icon' onClick={handleClick}>
                            <FontAwesomeIcon icon={faBars} style={{ color: "black" }} />
                            <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
                        </div>
                        <div className={click ? 'nav-menu active' : 'nav-menu'}>
                            <ul class="navbar-nav ms-auto mb-2 mb-lg-0" /*style={{flexDirection: 'row'}}*/>
                                <li class="nav-item">
                                    <Link class="nav-link active" style={{ color: '#fff' }} onClick={closeMobileMenu} to="/">Inicio</Link>
                                </li>
                                {/* <li class="nav-item">
                                    <Link class="nav-link" style={{ color: '#fff' }} onClick={closeMobileMenu} to="#">Productos</Link>
                                </li> */}
                                <li class="nav-item">
                                    <Link class="nav-link" style={{ color: '#fff' }} onClick={closeMobileMenu} to="/about">Conocimiento del Neumático</Link>
                                </li>
                                <li class="nav-item">
                                    <Link class="nav-link" style={{ color: '#fff' }} onClick={closeMobileMenu} to="/network">Redes de Distribución</Link>
                                </li>
                                <li class="nav-item">
                                    <Link class="nav-link" style={{ color: '#fff' }} onClick={closeMobileMenu} to="/contact">Contacto</Link>
                                </li>
                            </ul>

                        </div>
                    </div>
                </nav>

                <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.6.0/jquery.min.js" integrity="sha512-894YE6QWD5I59HgZOGReFYm4dnWc1Qt5NtvYSaNcOP+u1T9qYdvdihz0PPSiiqn/+/3e7Jo4EaG7TubfWGUrMQ==" crossorigin="anonymous" referrerpolicy="no-referrer"></script>
            </body>
        </>
    );
}

export default Navbar;