import React from "react";
import Accordion from 'react-bootstrap/Accordion';
import '../commerces/css commerces/Comercio.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faPhone, faLocationArrow } from '@fortawesome/free-solid-svg-icons';
import Preloading from "../sections/Preloading";
import Footer from '../../Footer';

function Comercio_sucre() {
    return (
        <>
            <Preloading />
            <body class="comerce" >
                <div >
                    <center>
                        <div class="acordion-comercio">
                            <br></br>
                            <h2>Redes de Distribución de Sucre</h2>
                            <br></br><br></br>
                            <Accordion style={{ width: '55%' }}>

                                <Accordion.Item eventKey="0">
                                    <Accordion.Header class="acordion-header">EL REY DEL CAUCHO, C.A.</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faInstagram} /> SIN INFORMACIÓN<br />
                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel:+584147932962" target="_blank">+58 414-7932962</a><br /><br />
                                        <div class="ubic">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d1648.5122965774933!2d-63.25342574178222!3d10.669128568188599!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTDCsDQwJzA4LjUiTiA2M8KwMTUnMTAuNiJX!5e0!3m2!1ses!2sve!4v1689257215431!5m2!1ses!2sve" width="400" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                {/* <Accordion.Item eventKey="0">
                                    <Accordion.Header class="acordion-header">ACCESORIOS LA BOUTIQUE DEL AUTOMOVIL, C.A.</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faLocationArrow} /> CALLE LIBERTAD C.C. PICHINCHA<br />
                                        <FontAwesomeIcon icon={faInstagram} /> <a className="contacts" href="https://www.instagram.com/tiendas.boutique/" target="_blank">@tiendas.boutique</a><br />
                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel:+584149849560" target="_blank">+58 414-9849560</a><br /><br />
                                        <div class="ubic">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d1648.5563915643938!2d-63.25518551054627!3d10.660990592307085!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTDCsDM5JzM5LjUiTiA2M8KwMTUnMTguMiJX!5e0!3m2!1ses!2sve!4v1687872188350!5m2!1ses!2sve" width="400" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item> */}

                                {/* <Accordion.Item eventKey="1">
                                    <Accordion.Header class="acordion-header">MULTICAUCHOS SAN MIGUEL, C.A.</Accordion.Header>
                                    <Accordion.Body>
                                       <FontAwesomeIcon icon={faLocationArrow} /> AV. UNIVERSITARIA SECTOR BELLO MONTE.<br></br>
                                        TELÉFONO: +58 414-7800620.<br></br><br />
                                        <div class="ubic">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1468.7515168266475!2d-63.25740965278415!3d10.649642520380509!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8c33dcd366bec367%3A0xc1616b2182a7b609!2sMulticauchos%20San%20Miguel!5e0!3m2!1sen!2sve!4v1685566690942!5m2!1sen!2sve" width="400" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item> */}

                            </Accordion><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br />
                        </div>
                    </center>
                </div>
            </body>
            <Footer />
        </>
    );
}

export default Comercio_sucre;