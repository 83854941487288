import React from "react";
import Accordion from 'react-bootstrap/Accordion';
import '../commerces/css commerces/Comercio.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faPhone, faLocationArrow } from '@fortawesome/free-solid-svg-icons';
import Preloading from "../sections/Preloading";
import Footer from '../../Footer';

function Comercio_apure() {
    return (
        <>
            <Preloading />
            <body class="comerce" >
                <div >
                    <center>
                        <div class="acordion-comercio">
                            <br />
                            <h2>Redes de Distribución de Apure</h2>
                            <br /><br /><br />
                            <Accordion style={{ width: '55%' }}>

                                <Accordion.Item eventKey="0">
                                    <Accordion.Header class="acordion-header">MULTI-INVERSIONES "LA RUEDA", C.A.</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faInstagram} /> <a className="contacts" href="https://www.instagram.com/larueda_c.a/" target="_blank">@larueda_c.a</a> <br />
                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel:+584245120160" target="_blank">+58 424-5120160</a><br /><br />
                                        <div class="ubic">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1976.0634344189307!2d-67.4813423098553!3d7.881792371593891!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e78874c20c57687%3A0xff8b112a5648921e!2sMULTI-INVERSIONES%20LA%20RUEDA!5e0!3m2!1sen!2sve!4v1689263542721!5m2!1sen!2sve" width="400" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="1">
                                    <Accordion.Header class="acordion-header">SERVICAUCHOS APURE, C.A.</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faInstagram} /> <a className="contacts" href="https://www.instagram.com/servicauchosapure/" target="_blank">@servicauchosapure</a> <br />
                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel:+584243311107" target="_blank">+58 424-3311107</a><br /><br />
                                        <div class="ubic">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1976.1204423737029!2d-67.48562754070448!3d7.869843401842598!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e7887370eff4bf5%3A0x3682b12f2d875396!2sServicauchos%20Apure%2C%20C.A!5e0!3m2!1sen!2sve!4v1689263711705!5m2!1sen!2sve" width="400" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>


                            </Accordion>
                            <br /><br /><br /><br />
                        </div>
                    </center>
                </div>
            </body>
            <Footer />
        </>
    );
}

export default Comercio_apure;