import React from 'react';
import './App.css';
import Home from './components/pages/Home';
import Navbar from './components/Navbar';
import { HashRouter as Router, Switch, Route } from 'react-router-dom';
import Aboutthetire from './components/pages/Aboutthetire';
import Distributionnetwork from './components/pages/Distributionnetwork';
import Contact from './components/pages/Contact';
import Comerciozulia from './components/pages/commerces/Comercio-zulia';
import Comerciotrujillo from './components/pages/commerces/Comercio-trujillo';
import Comerciotachira from './components/pages/commerces/Comercio-tachira';
import Comerciosucre from './components/pages/commerces/Comercio-sucre';
import Comercioportuguesa from './components/pages/commerces/Comercio-portuguesa';
import Comerciomonagas from './components/pages/commerces/Comercio-monagas';
import Comerciomiranda from './components/pages/commerces/Comercio-miranda';
import Comerciomerida from './components/pages/commerces/Comercio-merida';
import Comerciolara from './components/pages/commerces/Comercio-lara';
import Comercioguarico from './components/pages/commerces/Comercio-guarico';
import Comerciofalcon from './components/pages/commerces/Comercio-falcon';
import Comerciodc from './components/pages/commerces/Comercio-dc';
import Comerciocarabobo from './components/pages/commerces/Comercio-carabobo';
import Comerciobolivar from './components/pages/commerces/Comercio-bolivar';
import Comercioaragua from './components/pages/commerces/Comercio-aragua';
import Comercioapure from './components/pages/commerces/Comercio-apure';
import Comercioanzoategui from './components/pages/commerces/Comercio-anzoategui';


function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Switch>
          <Route path='/' exact component={Home} />
          <Route path='/about' component={Aboutthetire} />
          <Route path='/network' component={Distributionnetwork} />
          <Route path='/contact' component={Contact} />
          <Route path='/comercio-zulia' component={Comerciozulia} />
          <Route path='/comercio-trujillo' component={Comerciotrujillo} />
          <Route path='/comercio-tachira' component={Comerciotachira} />
          <Route path='/comercio-sucre' component={Comerciosucre} />
          <Route path='/comercio-portuguesa' component={Comercioportuguesa} />
          <Route path='/comercio-monagas' component={Comerciomonagas} />
          <Route path='/comercio-miranda' component={Comerciomiranda} />
          <Route path='/comercio-merida' component={Comerciomerida} />
          <Route path='/comercio-lara' component={Comerciolara} />
          <Route path='/comercio-guarico' component={Comercioguarico} />
          <Route path='/comercio-falcon' component={Comerciofalcon} />
          <Route path='/comercio-dc' component={Comerciodc} />
          <Route path='/comercio-carabobo' component={Comerciocarabobo} />
          <Route path='/comercio-bolivar' component={Comerciobolivar} />
          <Route path='/comercio-aragua' component={Comercioaragua} />
          <Route path='/comercio-apure' component={Comercioapure} />
          <Route path='/comercio-anzoategui' component={Comercioanzoategui} />

        </Switch>
      </Router>

    </>
  );
}

export default App;
