import React from "react";
import MainSection from "./sections/MainSection";
import Preloading from "./sections/Preloading";
import DescriptionSection from "./sections/DescriptionSection";
import TrayectorySection from "./sections/TrayectorySection";
import VideoSection from "./sections/VideoSection";
import MottoSection from "./sections/MottoSection";
import Footer from "../Footer";

function Home() {

    return (
        <>
            {/* Página de carga */}
            <Preloading />

            {/* Sección Principal */}
            <MainSection />

            {/* Sección de descripción */}
            <DescriptionSection />

            {/* Sección de trayectoria */}
            <TrayectorySection /><br />

            {/* Sección de Video */}
            <VideoSection />

            {/* Sección de Patrocinantes */}

            {/* Sección de Lema */}
            <MottoSection />

            {/* Sección de Mapa */}
            {/* <MapSection /> */}

            <Footer />
        </>
    );
}

export default Home;