import React from "react";
import '../pages/Contact.css';
import Footer from "../Footer";
import Preloading from "./sections/Preloading";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelopeOpen, faPhone } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';

function Contact3() {

    return (
        <>
            <Preloading />
            <div className="main-contacts">
                <h1 className="text-center">CONTACTOS</h1>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md">
                            <div className="contact">
                                <h4 className="subtitle">Teléfonos:</h4><br /><br /><br /><br /><br /><a style={{ paddingLeft: "5%" }} className="link-contacts" href="https://wa.link/byj1si" target="_blank"><FontAwesomeIcon icon={faWhatsapp} /></a>
                                <a style={{ paddingLeft: "20%" }} className="link-contacts" href="tel:+584246328415" target="_blank"><FontAwesomeIcon icon={faPhone} />{/*+58 424-6328415*/}</a>
                            </div>
                        </div><br />

                        <div className="col-md">
                            <div className="contact">
                                <h4 style={{ paddingLeft: "5%" }} className="subtitle">Correo Electrónico:</h4><br /><br /><br /><br /><br /><a className="link-contacts" href="mailto:info@cauchoslamundial.com" target="_blank"><FontAwesomeIcon icon={faEnvelopeOpen} /></a>
                            </div>
                        </div><br />

                        <div className="col-md">
                            <div className="contact">
                                <h4 style={{ paddingLeft: "5%" }} className="subtitle">Ubicación:</h4><br />
                                <div class="ubic">
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1960.9565625532973!2d-71.63101313840008!3d10.585965222663052!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e899772bf5d1d1b%3A0x1c0c2ab5928ca2b!2sCauchos%20La%20Mundial%20C.A.!5e0!3m2!1sen!2sve!4v1689283081541!5m2!1sen!2sve" width="400" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default Contact3;