import React from "react";
import Accordion from 'react-bootstrap/Accordion';
import '../commerces/css commerces/Comercio.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faPhone, faLocationArrow } from '@fortawesome/free-solid-svg-icons';
import Preloading from "../sections/Preloading";
import Footer from '../../Footer';

function Comercio_carabobo() {
    return (
        <>
            <Preloading />
            <body class="comerce" >
                <div >
                    <center>
                        <div class="acordion-comercio">
                            <br></br>
                            <h2>Redes de Distribución de Carabobo</h2>
                            <br></br><br></br>
                            <Accordion style={{ width: '55%' }}>
                                <br /><br /><br /><br />

                                <Accordion.Item eventKey="1">
                                    <Accordion.Header class="acordion-header">HM LLANTAS VALENCIA, C.A.</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faLocationArrow} /> ZONA INDUSTRIAL LOS GUAYOS SECTOR CARACARITOS COMPLEJO INDUSTRIAL LOS ROQUES<br />
                                        <FontAwesomeIcon icon={faInstagram} /> <a className="contacts" href="https://www.instagram.com/hmllantasvalencia/" target="_blank">@hmllantasvalencia</a> <br />
                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel:+584124102075" target="_blank">+58 412-4102075</a> <br /><br />
                                    </Accordion.Body>
                                </Accordion.Item>

                                {/* <Accordion.Item eventKey="0">
                                    <Accordion.Header class="acordion-header">TECNOCAUCHOS EXPRESS UNO, C.A.</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faLocationArrow} /> AV. ARANZAZU LOCAL NRO. 92 - 64 SECTOR CANDELARIA<br />
                                        <FontAwesomeIcon icon={faInstagram} /> SIN INFORMACIÓN <br />
                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel:+584144130039" target="_blank">+58 414-4130039</a> <br /><br />
                                    </Accordion.Body>
                                </Accordion.Item>

                                

                                <Accordion.Item eventKey="2">
                                    <Accordion.Header class="acordion-header">DISTRIBUIDORA LA PISTA, C.A.</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faLocationArrow} /> AV. 93 LOCAL 112-A-245 SECTOR LA CANDELARIA AV. LISANDRO ALVARADO VALENCIA<br />
                                        <FontAwesomeIcon icon={faInstagram} /> <a className="contacts" href="https://www.instagram.com/distribuidora.lapista.ca/" target="_blank">@distribuidora.lapista.ca</a> <br />
                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel:+584244113817" target="_blank">+58 424-4113817</a> <br /><br />
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="3">
                                    <Accordion.Header class="acordion-header">INVERPECO, C.A.</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faLocationArrow} /> CARRETERA NACIONAL GUACAR - LOS GUAYOS, SECTOR EL NARANJILLO<br />
                                        <FontAwesomeIcon icon={faInstagram} /> <a className="contacts" href="https://www.instagram.com/inverpeco/" target="_blank">@inverpeco</a> <br />
                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel:+584144253389" target="_blank">+58 414-4253389</a> <br /><br />
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="4">
                                    <Accordion.Header class="acordion-header">SPEED SHOP 01, C.A.</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faLocationArrow} /> AV. BOLÍVAR NORTE LOCAL NRO. 152 URB. GUAPARO<br />
                                        <FontAwesomeIcon icon={faInstagram} /> <a className="contacts" href="https://www.instagram.com/speedshop01val/" target="_blank">@speedshop01val</a> <br />
                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel:+584144853648" target="_blank">+58 414-4853648</a> <br /><br />
                                    </Accordion.Body>
                                </Accordion.Item> */}

                            </Accordion>
                            <br /><br /><br /><br />
                            <br /><br /><br /><br />

                        </div>
                    </center>
                </div>
            </body>
            <Footer />
        </>
    );
}

export default Comercio_carabobo;