import React from "react";
import './Footer.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram, faFacebook, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { Divider } from "@chakra-ui/react";

function Footer() {

    return (
        <>
            <Divider className="divisor-footer" />
            <footer className="footer_1">
                <div className="container">
                    <div className="row g-0">
                        <br /><br />
                        <div className="col-sm-2"></div>
                        <div className="col-sm-8">
                            <aside className="widget aboutwidget">
                                <p className="final-words" style={{ paddingBottom: '5%' }}>
                                    "En Nankang nos sentimos orgullosos de nuestros productos de calidad fabricados a lo largo de los años. Pero nunca
                                    cesamos en el empeño de conseguir la excelencia en nuestros procesos de fabricación".
                                </p>
                            </aside>
                        </div>
                        <div className="col-sm-2"></div>
                    </div>
                </div>


                <center>
                    <div className="row">
                        <div className="footer-horario">
                            <a href='/' className="social-logo">
                                <img src="images/logo-nankang.webp" width={200} />
                            </a>
                            <a href='https://www.cauchoslamundial.com/' target='_blank' className='social-logo'><img src="images/clm.webp" width={200} /></a>
                        </div>
                    </div>
                </center>
                <br /><br />


                <div className="container">
                    <div className="row g-0">
                        <div className="col-sm-4"></div>
                        <div className="col-sm-4">
                            <aside className="widget social_widget">
                                {/* <h3 className="widget_title">Social</h3> */}
                                <div className="links-options">

                                    <a className="icon-social" href="https://www.instagram.com/nankang.ve/" target="_blank"><FontAwesomeIcon icon={faInstagram} /></a>
                                    <a className="icon-social" href="https://www.facebook.com/nankang.ve/" target="_blank"><FontAwesomeIcon icon={faFacebook} /></a>
                                    <a className="icon-social" href="https://www.youtube.com/@NankangRubberTire" target="_blank"><FontAwesomeIcon icon={faYoutube} /></a>
                                </div>

                            </aside>
                        </div>
                        <div className="col-sm-4"></div>
                    </div>
                </div >
                <br />





                <div className="row">
                    <div className="col-sm-12 text-center">
                        <div className="copyright">
                            <a className="enlace" href="/" target="_blank">NANKANG Venezuela</a> © Todos los derechos reservados. Diseñado por Grupo La Mundial C.A.
                        </div><br />
                    </div>
                </div>
            </footer >
        </>
    );
}

export default Footer;