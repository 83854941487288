import React from "react";
import Accordion from 'react-bootstrap/Accordion';
import '../commerces/css commerces/Comercio.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faPhone, faLocationArrow } from '@fortawesome/free-solid-svg-icons';
import Preloading from "../sections/Preloading";
import Footer from '../../Footer';

function Comercio_dc() {
    return (
        <>
            <Preloading />
            <body class="comerce" >
                <div >
                    <center>
                        <div class="acordion-comercio">
                            <br></br>
                            <h2>Redes de Distribución de Distrito Capital</h2>
                            <br /><br /><br />
                            <Accordion style={{ width: '55%' }}>

                                <Accordion.Item eventKey="0">
                                    <Accordion.Header class="acordion-header">INVERSIONES LA GRAN CHISPA DEL CAUCHO C.M., C.A</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faLocationArrow} /> CALLE C 3 LA YAGUARA AV. INTERCOMUNAL LOCAL S/N URB. EL PARAISO<br />
                                        <FontAwesomeIcon icon={faInstagram} /> <a className="contacts" href="https://www.instagram.com/chispacauchoscombateria/" target="_blank">@chispacauchoscombateria</a><br />
                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel:+584142847811" target="_blank">+58 414-2847811</a><br /><br />
                                        <div class="ubic">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d1961.6624450379975!2d-66.96553496329474!3d10.475034093602114!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTDCsDI4JzMwLjAiTiA2NsKwNTcnNTMuMCJX!5e0!3m2!1ses!2sve!4v1687890307663!5m2!1ses!2sve" width="400" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                {/* <Accordion.Item eventKey="1">
                                    <Accordion.Header class="acordion-header">CAUCHOS SILVA Y MARQUEZ, C.A.</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faLocationArrow} /> AV. FRANCISCO SOLANO LOPEZ ENTRE 2DA Y 3RA AV. LAS DELICICAS LOCAL NRO. 03 PB URB. SABANA GRANDE<br />
                                        <FontAwesomeIcon icon={faInstagram} /> <a className="contacts" href="https://www.instagram.com/silvamarquez24h/" target="_blank">@silvamarquez24h</a><br />
                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel:+584125442023" target="_blank">+58 412-5442023</a><br /><br />
                                        <div class="ubic">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3923.0955011345845!2d-66.87109339999999!3d10.4931375!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8c2a58e260cbbcb7%3A0xf25af8414a5716a5!2sCauchos%20Silva%20Y%20Marquez!5e0!3m2!1sen!2sve!4v1687890478949!5m2!1sen!2sve" width="400" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="2">
                                    <Accordion.Header class="acordion-header">CAUCHOS LOS ESTADIOS, C.A.</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faLocationArrow} /> CALLE EL STADIUM SANZ Y RIZQUEZ.<br />
                                        <FontAwesomeIcon icon={faInstagram} /> <a className="contacts" href="https://www.instagram.com/cauchoslosestadios/" target="_blank">@cauchoslosestadios</a><br />
                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel:+584143385077" target="_blank">+58 414-3385077</a><br /><br />
                                        <div class="ubic">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3923.162363546856!2d-66.8727565!3d10.4878639!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8c2a58e43d85c405%3A0x1ff021dbf26eddbc!2sRubbers%20C.A%20Stadiums!5e0!3m2!1sen!2sve!4v1687890634777!5m2!1sen!2sve" width="400" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="3">
                                    <Accordion.Header class="acordion-header">AUTO WOLF, C.A.</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faInstagram} /> SIN INFORMACIÓN<br />
                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel:+584243333399" target="_blank">+58 424-3333399</a><br /><br />
                                        <div class="ubic">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d1961.4232156419368!2d-66.88961539982998!3d10.512759219755479!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTDCsDMwJzQ2LjkiTiA2NsKwNTMnMTguNSJX!5e0!3m2!1ses!2sve!4v1687890863951!5m2!1ses!2sve" width="400" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="4">
                                    <Accordion.Header class="acordion-header">CAUCHOS AUTOMOTRIZ LA RAMBLA, C.A.</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faInstagram} /> <a className="contacts" href="https://www.instagram.com/caucheralarambla/" target="_blank">@caucheralarambla</a><br />
                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel:+584241364336" target="_blank">+58 424-1364336</a><br /><br />
                                        <div class="ubic">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1166.377711806667!2d-66.9020426847734!3d10.483998810941705!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8c2a59f9810094e1%3A0xc21db9bec89b00b5!2sCauchos%20Automotriz%20La%20Rambla%20C.A!5e0!3m2!1ses!2sve!4v1687891081050!5m2!1ses!2sve" width="400" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="5">
                                    <Accordion.Header class="acordion-header">MULTISERVICIOS PRO SPEED H&L</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faInstagram} /> <a className="contacts" href="https://www.instagram.com/cauchosprospeed/" target="_blank">@cauchosprospeed</a><br />
                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel:+584242804072" target="_blank"></a> +58 424-2804072<br></br>
                                        <div class="ubic">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d1961.640336437691!2d-66.89098453896091!3d10.478526127246685!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTDCsDI4JzQxLjQiTiA2NsKwNTMnMjQuOSJX!5e0!3m2!1ses!2sve!4v1687891274811!5m2!1ses!2sve" width="400" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="6">
                                    <Accordion.Header class="acordion-header">100 MILLAS AUTO RACING, C.A.</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faInstagram} /> <a className="contacts" href="https://www.instagram.com/100millas.ve/" target="_blank">@100millas.ve</a><br />
                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel:+584126201005" target="_blank">+58 412-6201005</a> <br /><br />
                                        <div class="ubic">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2332.676297291986!2d-66.82697598438402!3d10.49449592523329!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8c2a59cb904ed0ed%3A0xd8cf3b71e65931cf!2s100%20millas%20auto%20racing%2C%20c.a.!5e0!3m2!1sen!2sve!4v1687891840495!5m2!1sen!2sve" width="400" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="7">
                                    <Accordion.Header class="acordion-header">NEUMASTER 28, C.A.</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faInstagram} /> <a className="contacts" href="https://www.instagram.com/neumaster28/" target="_blank">@neumaster28</a><br />
                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel:+584126444586" target="_blank">+58 412-6444586</a> <br /><br />
                                        <div class="ubic">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d824.7399317724571!2d-66.88189506404464!3d10.489124833908113!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8c2a58adcd824807%3A0x8cf6c7e0eab4815a!2sF4Q9%2BM74%2C%20Caracas%2C%20Distrito%20Capital!5e0!3m2!1sen!2sve!4v1687892240640!5m2!1sen!2sve" width="400" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="8">
                                    <Accordion.Header class="acordion-header">SPEED RACING, C.A.</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faLocationArrow} /> CALLE EL STADIUM SANZ Y RIZQUEZ<br />
                                        <FontAwesomeIcon icon={faInstagram} /> <a className="contacts" href="https://www.instagram.com/speedracingca/" target="_blank">@speedracingca</a><br />
                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel:+584141349898" target="_blank">+58 414-1349898</a><br /><br />
                                        <div class="ubic">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1387.0698063205314!2d-66.88749651724542!3d10.482853028150396!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8c2a58adcd824807%3A0x7c0ce54121dad3de!2sF4M7%2B53%2C%20Caracas%2C%20Distrito%20Capital!5e0!3m2!1sen!2sve!4v1687892827478!5m2!1sen!2sve" width="400" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="9">
                                    <Accordion.Header class="acordion-header">MULTISERVICIOS NATFRAN 2012, C.A.</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faInstagram} /> <a className="contacts" href="https://www.instagram.com/cambiatuneumaticoya/" target="_blank">@cambiatuneumaticoya</a><br />
                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel:+584123120357" target="_blank">+58 412-3120357</a><br /><br />
                                        <div class="ubic">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1166.593417283871!2d-66.827183896456!3d10.426582851074993!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8c2af7cd703e575f%3A0x36880daf9e8171de!2scambiatuneumaticoya!5e0!3m2!1sen!2sve!4v1687893024839!5m2!1sen!2sve" width="400" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="10">
                                    <Accordion.Header class="acordion-header">CAUCHOS PANAMERICANA I, C.A.</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faInstagram} /> <a className="contacts" href="https://www.instagram.com/cauchospanamericana1/" target="_blank">@cauchospanamericana1</a><br />
                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel:+584241451740" target="_blank">+58 424-1451740</a><br /><br />
                                        <div class="ubic">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d1387.5559831922649!2d-66.9709401078789!3d10.373754651687957!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTDCsDIyJzI1LjEiTiA2NsKwNTgnMTQuNiJX!5e0!3m2!1ses!2sve!4v1687893242034!5m2!1ses!2sve" width="400" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="11">
                                    <Accordion.Header class="acordion-header">BLANDIN TIRES CENTER, C.A.</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faInstagram} /> <a className="contacts" href="https://www.instagram.com/blandintirescenter/" target="_blank">@blandintirescenter</a><br />
                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel:+584169299371" target="_blank">+58 416-9299371</a><br /><br />
                                        <div class="ubic">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1387.0061012308488!2d-66.85520251182183!3d10.497065395917081!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8c2a59aa6d373e05%3A0x1ea745adc0e5eaab!2sCaucho%20Centro%20Blandin%2C%20C.A.!5e0!3m2!1sen!2sve!4v1687893531987!5m2!1sen!2sve" width="400" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="12">
                                    <Accordion.Header class="acordion-header">GRUPO ADB 2020, C.A.</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faInstagram} /> <a className="contacts" href="https://www.instagram.com/protires2020/" target="_blank">@protires2020</a><br />
                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel:+584241091552" target="_blank">+58 424-1091552</a><br /><br />
                                        <div class="ubic">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d1166.5060674195095!2d-66.82226146395797!3d10.449871011364971!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTDCsDI2JzU5LjkiTiA2NsKwNDknMTkuMCJX!5e0!3m2!1sen!2sve!4v1687893717301!5m2!1sen!2sve" width="400" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="13">
                                    <Accordion.Header class="acordion-header">INVERSORA CENTER CAUCHOS 2019, C.A</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faLocationArrow} /> CALLE LAS FUENTES QTA VERACRUZ<br /><br />
                                        <FontAwesomeIcon icon={faInstagram} /> <a className="contacts" href="https://www.instagram.com/capital_cauchos/" target="_blank">@capital_cauchos</a><br />
                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel:+584242888516" target="_blank">+58 424-2888516</a><br /><br />
                                        <div class="ubic">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1649.4968523595087!2d-66.93609857087888!3d10.485936988134318!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8c2a5fa8c52e2871%3A0x3b54e41be56a43fa!2sCapital%20cauchos!5e0!3m2!1ses!2sve!4v1687894751759!5m2!1ses!2sve" width="400" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="14">
                                    <Accordion.Header class="acordion-header">CAUCHOS LINA 2011, C.A</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faInstagram} /> <a className="contacts" href="https://www.instagram.com/cauchoslina2011/" target="_blank">@cauchoslina2011</a><br />
                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel:+584124093051" target="_blank">+58 412-4093051</a><br /><br />
                                        <div class="ubic">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1166.3499897917222!2d-66.82239388413991!3d10.49135525664211!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8c2a59ce65d2129f%3A0x710f3b51c0818e9d!2sCauchos%20Lina%202011%2C%20CA!5e0!3m2!1sen!2sve!4v1687894931634!5m2!1sen!2sve" width="400" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="15">
                                    <Accordion.Header class="acordion-header">INVERSIONES FORACAR 2010, C.A</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faInstagram} /> SIN INFORMACIÓN<br />
                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel:+584143662706" target="_blank">+58 414-3662706</a><br />
                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel:+584142262658" target="_blank">+58 414-2262658</a><br /><br />
                                        <div class="ubic">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d1387.0516594317558!2d-66.87015382515132!3d10.48690347124045!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTDCsDI5JzEyLjMiTiA2NsKwNTInMTIuNCJX!5e0!3m2!1sen!2sve!4v1687895118865!5m2!1sen!2sve" width="400" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="16">
                                    <Accordion.Header class="acordion-header">RACING RUEDAS 99, C.A</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faInstagram} />  <a className="contacts" href="https://www.instagram.com/racing.ruedas99/" target="_blank">@racing.ruedas99</a><br />
                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel:+584142046614" target="_blank">+58 414-2046614</a><br /><br />
                                        <div class="ubic">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1166.3145046410523!2d-66.83332633277406!3d10.500764328603893!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8c2a591818c12823%3A0xe8c9036abf3b1f23!2sRacing%20Ruedas%2099!5e0!3m2!1sen!2sve!4v1687895267993!5m2!1sen!2sve" width="400" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="17">
                                    <Accordion.Header class="acordion-header">MULTICAUCHOS Y FRENOS HAKKINEN 2020, C.A</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faInstagram} />  <a className="contacts" href="https://www.instagram.com/hakkinen4wd/" target="_blank">@hakkinen4wd</a><br />
                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel:+584128286903" target="_blank">+58 412-8286903</a><br /><br />
                                        <div class="ubic">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1166.3549881327594!2d-66.82370738031715!3d10.490029250058273!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8c2a5913ab124e4f%3A0xf1b1036dbd155237!2sMulticauchos%20y%20frenos%20Hakkinen%2C%20C.A!5e0!3m2!1sen!2sve!4v1687895414432!5m2!1sen!2sve" width="400" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="18">
                                    <Accordion.Header class="acordion-header">MULTISERVICIOS LA GUARITA PRIX, C.A</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faInstagram} />  <a className="contacts" href="https://www.instagram.com/laguairitaprix/" target="_blank">@laguairitaprix</a><br />
                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel:+584143665018" target="_blank">+58 414-3665018</a><br /><br />
                                        <div class="ubic">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1387.2048614890462!2d-66.82146293056402!3d10.452659529957891!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8c2a59cdb24cab67%3A0x9df929283ca9250c!2sMultiservicios%20la%20Guairita%20Prix%2C%20c.a.!5e0!3m2!1sen!2sve!4v1687895526798!5m2!1sen!2sve" width="400" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="19">
                                    <Accordion.Header class="acordion-header">NUEVA CARACAS CAUCHO 2021, C.A</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faInstagram} />  <a className="contacts" href="https://www.instagram.com/cauchosnuevacaracas/" target="_blank">@cauchosnuevacaracas</a><br />
                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel:+584143248352" target="_blank">+58 414-3248352</a><br /><br />
                                        <div class="ubic">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d980.7990076414538!2d-66.88741780023362!3d10.485207508100139!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTDCsDI5JzA2LjQiTiA2NsKwNTMnMTMuOCJX!5e0!3m2!1ses!2sve!4v1687895651707!5m2!1ses!2sve" width="400" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="20">
                                    <Accordion.Header class="acordion-header">MULTIAUTOS CORRALITO, C.A</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faInstagram} />  <a className="contacts" href="https://www.instagram.com/servicauchoslc/" target="_blank">@servicauchoslc</a><br />
                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel:+584121145423" target="_blank">+58 412-1145423</a><br /><br />
                                        <div class="ubic">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d825.1011859507212!2d-67.00667812898115!3d10.352693519240315!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8c2a8cc9371c717b%3A0x5c771fb7769f9ea8!2sMULTIAUTOS%20CORRALITO%2C%20C.A.!5e0!3m2!1ses!2sve!4v1687895821075!5m2!1ses!2sve" width="400" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="21">
                                    <Accordion.Header class="acordion-header">CAUCHOS HOY.COM, C.A</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faInstagram} />  <a className="contacts" href="https://www.instagram.com/cauchos_hoy/" target="_blank">@cauchos_hoy</a><br />
                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel:+584142445676" target="_blank">+58 414-2445676</a><br /><br />
                                        <div class="ubic">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3299.1680461305154!2d-66.55819019072135!3d10.469564848426348!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8c2bab701bbd6e55%3A0x368a2f28d66a8c05!2sCauchos%20Hoy!5e0!3m2!1sen!2sve!4v1687895962628!5m2!1sen!2sve" width="400" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="22">
                                    <Accordion.Header class="acordion-header">TECNICAUCHOS 5787, C.A</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faLocationArrow} /> AV. VILLA HEROICA DE GUATIRE DENTRO DE LA ESTACIÓN DE SERVICIO TEXACO, BAJO EL HOTEL PLATINUM<br />
                                        <FontAwesomeIcon icon={faInstagram} />  <a className="contacts" href="https://www.instagram.com/tecnicauchos/" target="_blank">@tecnicauchos</a><br />
                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel:+584143081710" target="_blank">+58 414-3081710</a><br /><br />
                                    </Accordion.Body>
                                </Accordion.Item> */}

                            </Accordion>
                            <br /><br /><br /><br />

                        </div>
                    </center>
                </div>
            </body>
            <Footer />
        </>
    );
}

export default Comercio_dc;