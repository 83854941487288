import React from "react";
import Accordion from 'react-bootstrap/Accordion';
import '../commerces/css commerces/Comercio.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faPhone, faLocationArrow } from '@fortawesome/free-solid-svg-icons';
import Preloading from "../sections/Preloading";
import Footer from '../../Footer';

function Comercio_trujillo() {
    return (
        <>
            <Preloading />
            <body class="comerce" >
                <div >
                    <center>
                        <div class="acordion-comercio">
                            <br></br>
                            <h2>Redes de Distribución de Trujillo</h2>
                            <br></br><br></br><br />
                            <Accordion style={{ width: '55%' }}>

                                <Accordion.Item eventKey="0">
                                    <Accordion.Header class="acordion-header">CAUCHOS MAYA, C.A.</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faLocationArrow} />CALLE 8 CON CALLE MAYA, PLATA 1, MUNICIPIO VALERA ESTADO TRUJILLO.<br></br>
                                        <FontAwesomeIcon icon={faInstagram} /> <a className="contacts" href="https://www.instagram.com/cauchosmaya1/" target="_blank">@cauchosmaya1</a> <br />
                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel:+584147359948" target="_blank">+58 414-7359948</a><br></br><br />
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="1">
                                    <Accordion.Header class="acordion-header">INVERSIONES TODO CAUCHO, C.A.</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faLocationArrow} /> CARRETERA NACIONAL, SECTOR SEGUNDA SABANA, DIAGONAL A LA ENTRADA DE LA URB. EL RINCÓN 2, MUNICIPIO BOCONO ESTADO TRUJILLO.<br></br>
                                        <FontAwesomeIcon icon={faInstagram} /> <a className="contacts" href="https://www.instagram.com/todocauchobcno/" target="_blank">@todocauchobcno</a><br />
                                        <FontAwesomeIcon icon={faPhone} /><a className="contacts" href="tel:+584140362985" target="_blank">+58 414-0362985</a><br></br><br />
                                    </Accordion.Body>
                                </Accordion.Item>

                                {/* <Accordion.Item eventKey="2">
                                    <Accordion.Header class="acordion-header">NEUMÁTICOS Y SERVICIOS ANDRADE, C.A.</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faLocationArrow} />CARRETERA NACIONAL SECTOR LA MILLA A 100 MTS DEL PUENTE LA MILLA.<br></br>
                                        <FontAwesomeIcon icon={faInstagram} /> SIN INFORMACIÓN <br />
                                        <FontAwesomeIcon icon={faPhone} /><a className="contacts" href="tel:+584247620421" target="_blank">+58 424-7620421</a><br></br><br />
                                    </Accordion.Body>
                                </Accordion.Item> */}

                                {/* <Accordion.Item eventKey="2">
                                    <Accordion.Header class="acordion-header">NEUMÁTICOS VALERA, C.A.</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faLocationArrow} /> AV. 10 ENTRE CALLES 4 Y 5 C.C. VICTOR HUGO.<br></br>
                                        <FontAwesomeIcon icon={faPhone} /> +58 414-7098558.<br></br><br />
                                    </Accordion.Body>
                                </Accordion.Item> */}

                                {/* <Accordion.Item eventKey="3">
                                    <Accordion.Header class="acordion-header">SERVICAUCHOS ANA</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faLocationArrow} /> CALLE 5 ENTRE AVENIDAS 11  12 C.C. ELENA.<br></br>
                                        <FontAwesomeIcon icon={faPhone} /> +58 414-7351172 / +58 414-1792935.<br></br><br />
                                    </Accordion.Body>
                                </Accordion.Item> */}

                                {/* <Accordion.Item eventKey="4">
                                    <Accordion.Header class="acordion-header">TIRES CENTER VALERA, C.A.</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faLocationArrow} /> AV. INDEPENDENCIA CON CALLE RIO DE JANEIRO.<br></br>
                                        <FontAwesomeIcon icon={faPhone} /> +58 271-2256723.<br></br><br />
                                        <div class="ubic">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2341.074627039989!2d-70.60193676697375!3d9.315315673472739!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e632984b01edc5d%3A0xa936a3ef4ad3a68e!2sTIRES%20CENTER%20VALERA%20CA!5e0!3m2!1sen!2sve!4v1685549398616!5m2!1sen!2sve" width="400" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item> */}

                            </Accordion>
                            <br /><br /><br /><br />
                            <br />
                        </div>
                    </center>
                </div>
            </body>
            <Footer />
        </>
    );
}

export default Comercio_trujillo;