import React from "react";
import Accordion from 'react-bootstrap/Accordion';
import '../commerces/css commerces/Comercio.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faPhone, faLocationArrow } from '@fortawesome/free-solid-svg-icons';
import Preloading from "../sections/Preloading";
import Footer from '../../Footer';

function Comercio_bolivar() {
    return (
        <>
            <Preloading />
            <body class="comerce" >
                <div >
                    <center>
                        <div class="acordion-comercio">
                            <br />
                            <h2>Redes de Distribución de Bolívar</h2>
                            <br /><br />
                            <Accordion style={{ width: '55%' }}>

                                <Accordion.Item eventKey="0">
                                    <Accordion.Header class="acordion-header">AUTOS MACAGUA, C.A.</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faLocationArrow} /> AV. PASEO CARONI LOCAL CENTER PARCELA<br />
                                        <FontAwesomeIcon icon={faInstagram} /> <a className="contacts" href="https://www.instagram.com/autosmacagua/" target="_blank">@autosmacagua</a> <br />
                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel:+584148928168" target="_blank">+58 414-8928168</a><br /><br />
                                        <div class="ubic">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1974.1160249682757!2d-62.76413516871621!3d8.279688617909532!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8dcbf93b87e1c031%3A0xdea518d27c954af4!2sAutos%20Macagua%2C%20ca.!5e0!3m2!1sen!2sve!4v1687899476437!5m2!1sen!2sve" width="400" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="1">
                                    <Accordion.Header class="acordion-header">SUPER CAUCHOS LA VICTORIA, C.A.</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faLocationArrow} /> AV. PERIMETRAL, SANTO DOMINGO, UPATA, BOLÍVAR<br />
                                        <FontAwesomeIcon icon={faInstagram} /> <a className="contacts" href="https://www.instagram.com/lavictoria.ca/" target="_blank">@lavictoria.ca</a> <br />
                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel:+584148810042" target="_blank">+58 414-8810042</a><br /><br />
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="2">
                                    <Accordion.Header class="acordion-header">MUTISERVICIOS Y CAUCHERA LOS GOCHOS, C.A</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faLocationArrow} /> AV. GERONIMO ORTAL CRUCE CON CALLE 4<br />
                                        <FontAwesomeIcon icon={faInstagram} /> <a className="contacts" href="https://www.instagram.com/caucheralosgochos/" target="_blank">@caucheralosgochos</a> <br />
                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel:+584148817822" target="_blank">+58 414-8817822</a><br /><br />
                                        <div class="ubic">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1395.600094968558!2d-62.66600178751239!3d8.366877007744938!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8dcbf177c81d4a03%3A0xf0973e81939a6ae0!2sMULTISERVICIOS%20Y%20CAUCHERA%20LOS%20GOCHOS%2C%20C.A.!5e0!3m2!1sen!2sve!4v1687899891174!5m2!1sen!2sve" width="400" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="3">
                                    <Accordion.Header class="acordion-header">SERVICAUCHOS ALEX, C.A.</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faLocationArrow} /> AV. MANUEL PIAR, CARRERA SOROCAIMA<br />
                                        <FontAwesomeIcon icon={faInstagram} /> <a className="contacts" href="https://www.instagram.com/servicauchos_alex/" target="_blank">@servicauchos_alex</a> <br />
                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel:+584249531453" target="_blank">+58 424-9531453</a><br /><br />
                                        <div class="ubic">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d986.8374206063663!2d-62.64752348252779!3d8.367220588383661!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8dcbf1ecb2a5fb81%3A0xf241fbe69b4d5f55!2sservicauchosalex!5e0!3m2!1sen!2sve!4v1687899763436!5m2!1sen!2sve" width="400" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="4">
                                    <Accordion.Header class="acordion-header">MULTICAUCHOS LA UNIDAD, C.A.</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faInstagram} /> <a className="contacts" href="https://www.instagram.com/cauchos_launidad/" target="_blank">@cauchos_launidad</a> <br />
                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel:+584249534602" target="_blank">+58 424-9534602</a><br /><br />
                                        <div class="ubic">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d2347.0878453111045!2d-62.65201725523497!3d8.370592174481617!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zOMKwMjInMTQuMCJOIDYywrAzOScwNi40Ilc!5e0!3m2!1sen!2sve!4v1689261773653!5m2!1sen!2sve" width="400" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="5">
                                    <Accordion.Header class="acordion-header">SUPER KAUCHOS, C.A</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faLocationArrow} /> CALLE RIO CARIBE EIDF. RIO C.A., PISO PB<br />
                                        <FontAwesomeIcon icon={faInstagram} /> <a className="contacts" href="https://www.instagram.com/superkauchos/" target="_blank">@superkauchos</a> <br />
                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel:+584249700126" target="_blank">+58 424-9700126</a><br />
                                        <div class="ubic">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1174.2816942431123!2d-63.56365408303771!3d8.122133082002424!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8dce873d20df19b9%3A0x7d6dfb781f34225c!2sSuper%20Kauchos!5e0!3m2!1sen!2sve!4v1687898612823!5m2!1sen!2sve" width="400" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="6">
                                    <Accordion.Header class="acordion-header">MULTICAUCHOS EL POLACO, C.A.</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faInstagram} /> <a className="contacts" href="https://www.instagram.com/multicauchoselpolaco/" target="_blank">@multicauchoselpolaco</a> <br />
                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel:+584249223671" target="_blank">+58 424-9223671</a><br /><br />
                                        <div class="ubic">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1975.4596668999857!2d-62.39417341852424!3d8.00725564459991!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8dc9677c0e998b69%3A0x45a25ce21384529c!2sMulticauchos%20El%20Polaco!5e0!3m2!1sen!2sve!4v1689261959020!5m2!1sen!2sve" width="400" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="7">
                                    <Accordion.Header class="acordion-header">CAUCHOS J.D, C.A.</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faLocationArrow} /> AV. PASEO CARONI LOCAL NRO. 2 SECTOR UNARE<br />
                                        <FontAwesomeIcon icon={faInstagram} /> <a className="contacts" href="https://www.instagram.com/grupojdpzo/" target="_blank">@grupojdpzo</a> <br />
                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel:+584148755144" target="_blank">+58 414-8755144</a><br /><br />
                                        <div class="ubic">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2791.831103781275!2d-62.76689746186723!3d8.278356137960817!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8dcbf96edef6b0bd%3A0xdf4add8b60c9f9d6!2sCauchos%20JD%2C%20C.A.!5e0!3m2!1sen!2sve!4v1687898783003!5m2!1sen!2sve" width="400" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>




                                {/* <Accordion.Item eventKey="0">
                                    <Accordion.Header class="acordion-header">AUTO ACCESORIOS DON VENTURA, C.A.</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faLocationArrow} /> AV. NUEVA GRANADA EDIF. LA TRINIDAD NRO. 31<br />
                                        <FontAwesomeIcon icon={faInstagram} /> <a className="contacts" href="https://www.instagram.com/autoaccesoriosdonventura/" target="_blank">@autoaccesoriosdonventura</a> <br />
                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel:+584249351661" target="_blank">+58 424-9351661</a><br /><br />
                                        <div class="ubic">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1396.501520081082!2d-63.5459442758945!3d8.111381288963718!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8dce87baa702d181%3A0x620f49d4a52584b9!2sAuto%20Accesorios%20Don%20Ventura%20C.A!5e0!3m2!1sen!2sve!4v1687898233887!5m2!1sen!2sve" width="400" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="1">
                                    <Accordion.Header class="acordion-header">DISTRIBUIDORA DICE, C.A</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faLocationArrow} /> CALLE RIO CARIBE EIDF. RIO C.A., PISO PB<br />
                                        <FontAwesomeIcon icon={faInstagram} /> <a className="contacts" href="https://www.instagram.com/grupodice/" target="_blank">@grupodice</a> <br />
                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel:+584249417260" target="_blank">+58 424-9417260</a><br />
                                        <div class="ubic">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d986.9727645715637!2d-62.70681302212487!3d8.313623909775687!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8dcbfa471a579d01%3A0xe10adb99a6bea714!2sDistribuidora%20Dice%2C%20C.A.!5e0!3m2!1sen!2sve!4v1687898649513!5m2!1sen!2sve" width="400" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>


                                <Accordion.Item eventKey="4">
                                    <Accordion.Header class="acordion-header">SERVICAUCHOS UNARE, C.A.</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faLocationArrow} /> CALLE PRINCIPAL LOCAL ND2 SECTOR COMPLEJO<br />
                                        <FontAwesomeIcon icon={faInstagram} /> <a className="contacts" href="https://www.instagram.com/servicauchosunare_/" target="_blank">@servicauchosunare_</a> <br />
                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel:+584148855523" target="_blank">+58 414-8855523</a><br /><br />
                                        <div class="ubic">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d1395.9220318064995!2d-62.76966687099104!3d8.276527944650772!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zOMKwMTYnMzUuNiJOIDYywrA0NicxMC40Ilc!5e0!3m2!1sen!2sve!4v1687898915629!5m2!1sen!2sve" width="400" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>



                                <Accordion.Item eventKey="6">
                                    <Accordion.Header class="acordion-header">MULTICAUCHOS EL GOCHO, C.A.</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faInstagram} /> <a className="contacts" href="https://www.instagram.com/multicauchoselgocho/" target="_blank">@multicauchoselgocho</a> <br />
                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel:+584148817822" target="_blank">+58 414-8817822</a><br /><br />
                                        <div class="ubic">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d1173.5836576526553!2d-62.63341041508363!3d8.357397531947747!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zOMKwMjEnMjUuOCJOIDYywrAzOCcwMC4wIlc!5e0!3m2!1sen!2sve!4v1687899296869!5m2!1sen!2sve" width="400" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>



                                <Accordion.Item eventKey="8">
                                    <Accordion.Header class="acordion-header">CAUCHOS CUMANA, C.A.</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faLocationArrow} /> AV. PASEO CARONI LOCAL CENTER PARCELA<br />
                                        <FontAwesomeIcon icon={faInstagram} /> <a className="contacts" href="https://www.instagram.com/cauchoscumanaca/" target="_blank">@cauchoscumanaca</a> <br />
                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel:+584148546969" target="_blank">+58 414-8546969</a><br /><br />
                                        <div class="ubic">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1660.6109842216993!2d-63.548823827862805!3d8.140030454009214!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8dce871659fcf307%3A0x9ff08d233b37c55a!2sCauchos%20Cumana%2C%20C.A.!5e0!3m2!1sen!2sve!4v1687899586160!5m2!1sen!2sve" width="400" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item> */}





                            </Accordion><br /><br />
                        </div>
                    </center>
                </div>
            </body>
            <Footer />
        </>
    );
}

export default Comercio_bolivar;