import React from "react";
import Accordion from 'react-bootstrap/Accordion';
import '../commerces/css commerces/Comercio.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faPhone, faLocationArrow } from '@fortawesome/free-solid-svg-icons';
import Preloading from "../sections/Preloading";
import Footer from '../../Footer';

function Comercio_guarico() {
    return (
        <>
            <Preloading />
            <body class="comerce" >
                <div >
                    <center>
                        <div class="acordion-comercio">
                            <br></br>
                            <h2>Redes de Distribución de Guárico</h2>
                            <br></br><br></br>
                            <Accordion style={{ width: '55%' }}>

                                <Accordion.Item eventKey="0">
                                    <Accordion.Header class="acordion-header">NEXTREM, C.A.</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faInstagram} /> <a className="contacts" href="https://www.instagram.com/nextremca/" target="_blank">@nextremca</a><br />
                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel:+584121974182" target="_blank">+58 412-1974182</a><br /><br />
                                        <div class="ubic">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1963.6948600241383!2d-64.67555250336576!3d10.148942303201178!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8c2d7369865209e1%3A0xad08eccf477f426c!2sNextrem!5e0!3m2!1sen!2sve!4v1689260094057!5m2!1sen!2sve" width="400" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="1">
                                    <Accordion.Header class="acordion-header">GRAN CAUCHO, C.A.</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faLocationArrow} /> AV. FRANCISCO DE MIRANDA VIA SAN FERNANDO DE APURE LOCAL GALPÓN S/N SECTOR PUENTE ALDAO CALABOZO GUÁRICO ZONA POSTAL 2312<br />
                                        <FontAwesomeIcon icon={faInstagram} /> <a className="contacts" href="https://www.instagram.com/grancaucho/" target="_blank">@grancaucho</a><br />
                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel:+584127796316" target="_blank">+58 412-7796316</a><br /><br />
                                        <div class="ubic">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2343.8076296641957!2d-67.45086066634683!3d8.898283054782802!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e7f61cf83ce5f57%3A0x38a46642173be6db!2sGran%20Caucho!5e0!3m2!1sen!2sve!4v1687880513015!5m2!1sen!2sve" width="400" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>


                                {/* <Accordion.Item eventKey="1">
                                    <Accordion.Header class="acordion-header">INVERCAUCHOS AMERICANA, C.A.</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faLocationArrow} /> AV. MIRANDA LOCAL NRO S/N SECTOR CENTRO.<br></br>
                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel:+584247246904" target="_blank"></a> +58 414-1478561.<br></br><br />
                                    </Accordion.Body>
                                </Accordion.Item> */}



                                {/* <Accordion.Item eventKey="2">
                                    <Accordion.Header class="acordion-header">HIPERCAUCHOS VENEZUELA, C.A.</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faLocationArrow} /> AV. FERMÍN TORO LOCAL NRO. 11 SECTOR FERMÍN TORO SAN JUAN DE LOS MORROS.<br></br>
                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel:+584247246904" target="_blank"></a> SIN INFORMACIÓN.<br></br><br />
                                        <div class="ubic">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1652.5248028583444!2d-67.3595918614441!3d9.901564353913622!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8c2aa92a8b8bb86f%3A0x7b3e2d7364489736!2sHipercauchos%20Venezuela%20C.A!5e0!3m2!1sen!2sve!4v1685566362062!5m2!1sen!2sve" width="400" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item> */}

                            </Accordion><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br>
                        </div>
                    </center>
                </div>
            </body>
            <Footer />
        </>
    );
}

export default Comercio_guarico;