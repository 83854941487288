import React from "react";
import Accordion from 'react-bootstrap/Accordion';
import '../commerces/css commerces/Comercio.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faPhone, faLocationArrow } from '@fortawesome/free-solid-svg-icons';
import Preloading from "../sections/Preloading";
import Footer from '../../Footer';

function Comercio_anzoategui() {
    return (
        <>
            <Preloading />
            <body class="comerce" >
                <div >
                    <center>
                        <div class="acordion-comercio">
                            <br />
                            <h2>Redes de Distribución de Anzoategui</h2>
                            <br /><br /><br />
                            <Accordion style={{ width: '55%' }}>

                                <Accordion.Item eventKey="0">
                                    <Accordion.Header class="acordion-header">EL GALPONAZO DEL HOGAR, C.A.</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faLocationArrow} /> AV. MUNICIPAL HACIENDO ESQUINA CON CALLE ZAMORA Y CALLE LA FE<br />
                                        <FontAwesomeIcon icon={faInstagram} /> SIN INFORMACIÓN <br />
                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel:+584148375409" target="_blank">+58 414-8375409</a><br /><br />

                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="1">
                                    <Accordion.Header class="acordion-header">NEUMÁTICOS D´AUTOS & STORE 4X4, C.A.-S. CUMANA</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faLocationArrow} /> AV. EL EJÉCITO DE BARCELONA, FRENTE AL CENTRAL MADEIRENSE<br />
                                        <FontAwesomeIcon icon={faInstagram} /> SIN INFORMACIÓN <br />
                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel:+584248937278" target="_blank">+58 424-8937278</a><br /><br />
                                        <div class="ubic">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1388.5436174073727!2d-64.69390005141068!3d10.148558334759363!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8c2d734a228a3e45%3A0xa6088476eaa3230c!2sNEUMATICOS%20D%60%20AUTOS%20%26%20STORE%204X4%2C%20C.A.!5e0!3m2!1sen!2sve!4v1687902122103!5m2!1sen!2sve" width="400" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="2">
                                    <Accordion.Header class="acordion-header">MULTISERVICIOS Y AUTOPARTES LA MILLA, C.A.</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faInstagram} /> <a className="contacts" href="https://www.instagram.com/multiservicioslamilla/" target="_blank">@multiservicioslamilla</a> <br />
                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel:+582832555235" target="_blank">+58 283-2555235</a><br /><br />
                                        <div class="ubic">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1657.3711180544917!2d-64.1705475089849!3d8.887490095538258!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8dcd83c17f1ced6f%3A0xefbe16fd62e0253b!2sMULTISERVICIOS%20Y%20AUTOPARTES%20LA%20MILLA%2C%20C.A.!5e0!3m2!1sen!2sve!4v1689262974164!5m2!1sen!2sve" width="400" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>


                                {/* <Accordion.Item eventKey="0">
                                    <Accordion.Header class="acordion-header">MASTER CAUCHOS PUERTO LA CRUZ, C.A.</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faLocationArrow} /> AV. MUNICIPAL LOCAL ND204<br />
                                        <FontAwesomeIcon icon={faInstagram} /> <a className="contacts" href="https://www.instagram.com/mastercauchosplc/" target="_blank">@mastercauchosplc</a> <br />
                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel:+584248106703" target="_blank">+58 424-8106703</a><br /><br />
                                        <div class="ubic">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2776.5802686463076!2d-64.63503736510928!3d10.206825407072836!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8c2d755c36fa2e07%3A0x75234734cdef9311!2sMaster%20Cauchos%20Puerto%20La%20Cruz%2C%20C.A!5e0!3m2!1sen!2sve!4v1687901691660!5m2!1sen!2sve" width="400" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="1">
                                    <Accordion.Header class="acordion-header">LA CASA DE LOS CAUCHOS, C.A.</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faLocationArrow} /> AV. PRINCIPAL DE LECHERIAS EDIF. CERAMICO<br />
                                        <FontAwesomeIcon icon={faInstagram} /> SIN INFORMACIÓN <br />
                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel:+584128401013" target="_blank">+58 412-8401013</a><br /><br />
                                        <div class="ubic">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d4537.096205136872!2d-64.69174429099476!3d10.17881662483385!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8c2d7397135d1845%3A0x82edf7ae0550764!2sLA%20CASA%20DE%20LOS%20CAUCHOS%2C%20C.A.!5e0!3m2!1sen!2sve!4v1687901792372!5m2!1sen!2sve" width="400" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>



                                <Accordion.Item eventKey="3">
                                    <Accordion.Header class="acordion-header">MULTISERVICIOS 27-15, C.A.</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faLocationArrow} /> AV. ESPAÑA EDIFICIO ENRIGIUSI.<br />
                                        <FontAwesomeIcon icon={faInstagram} /> <a className="contacts" href="https://www.instagram.com/mservicios27_15/" target="_blank">@mservicios27_15</a> <br />
                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel:+584121920068" target="_blank">+58 412-1920068</a><br /><br />
                                        <div class="ubic">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1393.6643713215112!2d-64.26298225824246!3d8.890923150696658!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8dcd81ce187f8131%3A0xa19a7e99a6efc904!2sMultiservicios%2027-15!5e0!3m2!1sen!2sve!4v1685559283037!5m2!1sen!2sve" width="400" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item> */}

                            </Accordion>
                            <br /><br /><br /><br />
                        </div>
                    </center>
                </div>
            </body>
            <Footer />
        </>
    );
}

export default Comercio_anzoategui;