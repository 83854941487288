import React from "react";
import Accordion from 'react-bootstrap/Accordion';
import '../commerces/css commerces/Comercio.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faPhone, faLocationArrow } from '@fortawesome/free-solid-svg-icons';
import Preloading from "../sections/Preloading";
import Footer from '../../Footer';

function Comercio_tachira() {
    return (
        <>
            <Preloading />
            <body class="comerce" >
                <div >
                    <center>
                        <div class="acordion-comercio">
                            <br></br>
                            <h2>Redes de Distribución de Táchira</h2>
                            <br /><br />
                            <Accordion style={{ width: '55%' }}>

                                <Accordion.Item eventKey="0">
                                    <Accordion.Header class="acordion-header">MECA CAUCHOS, C.A.</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faLocationArrow} /> CALLE 1 LOCAL NRO. 9-31 BARRIO LA GUACARA<br />
                                        <FontAwesomeIcon icon={faInstagram} /> <a className="contacts" href="https://www.instagram.com/meca.cauchos/" target="_blank">@meca.cauchos</a>
                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel:+584147506682" target="_blank">+58 414-7506682</a><br></br><br />
                                        <div className="ubic">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1397.690953605618!2d-72.22933346810923!3d7.761482549411645!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e666d45e45910c7%3A0x4bc65ae08a8ac9e0!2sMeca%20Cauchos%20CA!5e0!3m2!1sen!2sve!4v1685547376787!5m2!1sen!2sve" width="400" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="1">
                                    <Accordion.Header class="acordion-header">MULTISERVICIOS S. J CHACON</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faLocationArrow} /> CTRA. PANAMERICANA VEREDA LA CURVA CASA N<br />
                                        <FontAwesomeIcon icon={faInstagram} /> <a className="contacts" href="https://www.instagram.com/sjchaconmultiservicios/" target="_blank">@sjchaconmultiservicios</a><br />
                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel:+584267704624" target="_blank">+58 426-7704624</a><br /><br />
                                        <div className="ubic">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1661.6363670030023!2d-72.25339746418447!3d7.888835824059611!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e66693d7889fabd%3A0x355f062d073d86b3!2sMultiservicios%20S%20J%20CHACON!5e0!3m2!1sen!2sve!4v1685548574313!5m2!1sen!2sve" width="400" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="2">
                                    <Accordion.Header class="acordion-header">SERVICAUCHOS SIAVEN</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faInstagram} /> SIN INFORMACIÓN<br />
                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel:+584147548780" target="_blank">+58 414-7548780</a><br /><br />
                                        <div className="ubic">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d988.1923455147761!2d-72.44071980775354!3d7.814219279411793!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zN8KwNDgnNTAuOCJOIDcywrAyNicyNi40Ilc!5e0!3m2!1ses!2sve!4v1689256726599!5m2!1ses!2sve" width="400" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="3">
                                    <Accordion.Header class="acordion-header">DISTRICAUCHOS IMPORT</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faLocationArrow} /> AV. VENEZUELA CALLE 7<br />
                                        <FontAwesomeIcon icon={faInstagram} /> SIN INFORMACIÓN <br />
                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel:+584147225036" target="_blank">+58 414-7225036</a><br /><br />
                                        <div className="ubic">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1976.3828686146849!2d-72.4444466080387!3d7.81460424362055!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e6646c380d71cb1%3A0x5f1003b965f15d3f!2sC.%207%2C%20San%20Antonio%20del%20T%C3%A1chira%205007%2C%20T%C3%A1chira!5e0!3m2!1sen!2sve!4v1687870569277!5m2!1sen!2sve" width="400" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="4">
                                    <Accordion.Header class="acordion-header">IMPORT CAUCHOS LA FRIA, C.A.</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faLocationArrow} /> CALLE 4 CASA S/N BARRIO BOLÍVAR SAN CRISTOBAL TÁCHIRA ZONA POSTAL 5001<br />
                                        <FontAwesomeIcon icon={faInstagram} /> <a className="contacts" href="https://www.instagram.com/cauchosjclafria/" target="_blank">@cauchosjclafria</a><br />
                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel:+584147092286" target="_blank">+58 414-7092286</a><br /><br />
                                        <div className="ubic">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d987.2188561100475!2d-72.24830443143954!3d8.215281285737943!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zOMKwMTInNTUuMSJOIDcywrAxNCc1Mi41Ilc!5e0!3m2!1ses!2sve!4v1687870391284!5m2!1ses!2sve" width="400" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>



                                {/* <Accordion.Item eventKey="0">
                                    <Accordion.Header class="acordion-header">SUPER CAUCHOS USECHE K9</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faLocationArrow} /> VDA. 10 , CALLE EL HIGUERON CASA NRO. 10-16<br></br>
                                        <FontAwesomeIcon icon={faInstagram} /> <a className="contacts" href="https://www.instagram.com/supercauchos_usechek9/" target="_blank">@supercauchos_usechek9</a><br />
                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel:+584140749648" target="_blank">+58 414-0749648</a><br /><br />
                                        <div className="ubic">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d2794.8933512616877!2d-72.24681404644028!3d7.8346094723860595!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zN8KwNTAnMDQuNCJOIDcywrAxNCc0My42Ilc!5e0!3m2!1ses!2sve!4v1687815478322!5m2!1ses!2sve" width="400" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="1">
                                    <Accordion.Header class="acordion-header">ITAL CAUCHOS SERVICE C.A.</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faInstagram} /> <a className="contacts" href="https://www.instagram.com/italcauchos_sc/" target="_blank">@italcauchos_sc</a><br />
                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel:+584244265770" target="_blank"></a> +58 424-4265770<br /><br />
                                        <div className="ubic">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1662.2101392088964!2d-72.2279670819718!3d7.744744148193067!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e66135179b0af4b%3A0x2c71723fd7a47c57!2sRuedamax%20La%20Rotaria%20Ital%20Cauchos!5e0!3m2!1ses!2sve!4v1687869306299!5m2!1ses!2sve" width="400" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="2">
                                    <Accordion.Header class="acordion-header">TIRE EXPRES KIKE</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faLocationArrow} /> CR. 19 CON CALLE 13 EDIF. VICTORIA<br></br>
                                        <FontAwesomeIcon icon={faInstagram} /> <a className="contacts" href="https://www.instagram.com/tire_express_kike/" target="_blank">@tire_express_kike</a>
                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel:+584247787227" target="_blank">+58 424-7787227</a> <br></br><br />
                                        <div className="ubic">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d1976.5853774532711!2d-72.2216746420724!3d7.771710631977799!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zN8KwNDYnMTkuNCJOIDcywrAxMycxNi45Ilc!5e0!3m2!1ses!2sve!4v1687869432655!5m2!1ses!2sve" width="400" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>


                                <Accordion.Item eventKey="4">
                                    <Accordion.Header class="acordion-header">INVERSIONES JS 2020, C.A</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faLocationArrow} /> CTRA PANAMERICANA CASA NRO. 9-12<br />
                                        <FontAwesomeIcon icon={faInstagram} /> <a className="contacts" href="https://www.instagram.com/inversiones.js2020/" target="_blank">@inversiones.js2020</a>
                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel:+584126620325" target="_blank">+58 412-6620325</a><br /><br />
                                        <div className="ubic">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1661.8954431584518!2d-72.21242124072648!3d7.824100465603106!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e666d548fe6d321%3A0x360d433003010b49!2sInversiones%20Js!5e0!3m2!1sen!2sve!4v1685548305501!5m2!1sen!2sve" width="400" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="5">
                                    <Accordion.Header class="acordion-header">INVERCAUCHO COLONCITO F.P.</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faLocationArrow} /> CARRETERA 4 CASA 5-19 SECTOR CASCO CENTRAL COLONCITO<br />
                                        <FontAwesomeIcon icon={faInstagram} /> SIN INFORMACIÓN <br />
                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel:+584247003536" target="_blank">+58 424-7003536</a><br /><br />
                                    </Accordion.Body>
                                </Accordion.Item>


                                <Accordion.Item eventKey="9">
                                    <Accordion.Header class="acordion-header">SERVI FRENOS LA CUARTA, C.A</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faLocationArrow} /> CTRA PRINCIPAL, LA QUINTA - LA GRITA<br />
                                        <FontAwesomeIcon icon={faInstagram} /> <a className="contacts" href="https://www.instagram.com/servifrenoslc/" target="_blank">@servifrenoslc</a><br />
                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel:+584147456151" target="_blank">+58 414-7456151</a><br /><br />
                                        <div className="ubic">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3321.130618982612!2d-72.00563644378722!3d8.151040773325338!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zOMKwMDknMDUuOCJOIDcywrAwMCcxNi43Ilc!5e0!3m2!1ses!2sve!4v1687870933972!5m2!1ses!2sve" width="400" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="10">
                                    <Accordion.Header class="acordion-header">OMCAR´S 17, C.A.</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faLocationArrow} /> CALLE PRINCIPAL PASEO MURACHI<br />
                                        <FontAwesomeIcon icon={faInstagram} /> <a className="contacts" href="https://www.instagram.com/omcar_s17/" target="_blank">@omcar_s17</a><br />
                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel:+584247745404" target="_blank">+58 424-7745404</a><br /><br />
                                        <div className="ubic">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d1397.8416239609383!2d-72.2473985370222!3d7.7160345317337145!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zN8KwNDInNTguMSJOIDcywrAxNCc0Ny43Ilc!5e0!3m2!1ses!2sve!4v1687871866456!5m2!1ses!2sve" width="400" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item> */}

                            </Accordion><br></br><br></br>
                        </div>
                    </center>
                </div>
            </body>
            <Footer />
        </>
    );
}

export default Comercio_tachira;