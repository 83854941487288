import React from "react";
import { Carousel, Card} from 'react-bootstrap';
import './MainSection.css';
import 'bootstrap/dist/css/bootstrap.min.css';



function MainSection() {

    return (
        <>
            <div className="main-container">
                <Card className="responsive" >
                    <Carousel fade>
                        <Carousel.Item className="content-image">
                            <img src="./images/banner.webp"  />
    
                        </Carousel.Item>
                        <Carousel.Item className="content-image">
                            <img src="./images/banner-2.webp"  />

                        </Carousel.Item>
                        <Carousel.Item className="content-image">
                            <img src="./images/banner-3.webp"  />

                        </Carousel.Item>
                    </Carousel>
                </Card>

            </div>
        </>
    );
}

export default MainSection;