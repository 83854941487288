import React from "react";
import './Preloading.css';

function Preloading() {

    const [showLoader, setShowLoader] = React.useState(true);

    React.useEffect(() => {
        setTimeout(() => {
            setShowLoader(false);
        }, 1500);
    }, []);

    return (
        <>
            <body className={showLoader ? 'preload-page' : 'preload-page hide'}>
                <div className="preloader text-center">
                    <center>
                        <img className="" src="./images/loading-nankang.gif" style={{ marginTop: '20%' }} />
                    </center>
                </div>
            </body>
        </>
    );
}

export default Preloading;




