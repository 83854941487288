import React from "react";
import Accordion from 'react-bootstrap/Accordion';
import '../commerces/css commerces/Comercio.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faPhone, faLocationArrow } from '@fortawesome/free-solid-svg-icons';
import Preloading from "../sections/Preloading";
import Footer from '../../Footer';

function Comercio_merida() {
    return (
        <>
            <Preloading />
            <body class="comerce" >
                <div >
                    <center>
                        <div class="acordion-comercio">
                            <br />
                            <h2>Redes de Distribución de Mérida</h2>
                            <br /><br />
                            <Accordion style={{ width: '55%' }}>

                                <Accordion.Item eventKey="0">
                                    <Accordion.Header class="acordion-header">FERRESERVICIOS PANAMERICANA, C.A.</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faLocationArrow} /> CARRETERA PANAMERICANA NRO. 68 SECTOR LA BLANCA VIGIA MÉRIDA<br />
                                        <FontAwesomeIcon icon={faInstagram} /> <a className="contacts" href="https://www.instagram.com/ferrepanamca/" target="_blank">@ferrepanamca</a><br />
                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel:+584247246904" target="_blank">+58 424-7246904</a><br /><br />
                                        <div class="ubic">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d1972.379598995978!2d-71.6235127564342!3d8.619101789246711!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zOMKwMzcnMDguNSJOIDcxwrAzNycyMy4yIlc!5e0!3m2!1ses!2sve!4v1687875584976!5m2!1ses!2sve" width="400" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="1">
                                    <Accordion.Header class="acordion-header">MEGA BAT</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faLocationArrow} />AVENIDA CENTENARIO C.C. CENTENARIO NIVEL PB LOCAL LC-72 SECTOR EJIDO, MÉRIDA<br />
                                        <FontAwesomeIcon icon={faInstagram} /> <a className="contacts" href="https://www.instagram.com/mega.bat/?hl=en" target="_blank">@mega.bat</a><br />
                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel:+584147089359" target="_blank">+58 414-7089359</a><br /><br />
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="2">
                                    <Accordion.Header class="acordion-header">INVERSIONES DAYTONA GROUP, F.P.</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faLocationArrow} />CARRETERA 4TA VÍA BAILADORES, SECTOR EL LLANO 200 MTS ARRIBA DEL HOSPITAL SAN JOSÉ<br />
                                        <FontAwesomeIcon icon={faInstagram} /> <a className="contacts" href="https://www.instagram.com/rmendez9744/" target="_blank">@rmendez9744</a><br />
                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel:+584147577996" target="_blank">+58 414-7577996</a><br /><br />
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="3">
                                    <Accordion.Header class="acordion-header">BELEN TIRES CENTER, C.A.</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faLocationArrow} />AV. 8 ENTRE CALLES 17 Y 18, MÉRIDA<br />
                                        <FontAwesomeIcon icon={faInstagram} /> <a className="contacts" href="https://www.instagram.com/belentirescenterca1/" target="_blank">@belentirescenterca1</a><br />
                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel:+584145805970" target="_blank">+58 414-5805970</a><br /><br />
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="4">
                                    <Accordion.Header class="acordion-header">NEUMÁTICOS NEWMAN, C.A.</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faLocationArrow} />CALLE COMERCIO, AL LADO DE ABASTO HERMANOS VERGARA, CHIGUARA - EDO. MÉRIDA<br />
                                        <FontAwesomeIcon icon={faInstagram} /> <a className="contacts" href="https://www.instagram.com/nnewmanca/" target="_blank">@nnewmanca</a><br />
                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel:+584143550222" target="_blank">+58 414-3550222</a><br /><br />
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="5">
                                    <Accordion.Header class="acordion-header">MULTISERVICIO J.A., C.A.</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faInstagram} /> <a className="contacts" href="https://www.instagram.com/multiservicioj_a/" target="_blank">@multiservicioj_a</a><br />
                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel:+584247048903" target="_blank">+58 424-7048903</a><br /><br />
                                        <div class="ubic">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d1658.6162338694232!2d-71.66028464665516!3d8.607858819115284!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zOMKwMzYnMjguNSJOIDcxwrAzOSczNC45Ilc!5e0!3m2!1ses!2sve!4v1687876426528!5m2!1ses!2sve" width="400" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>



                                {/* <Accordion.Item eventKey="2">
                                    <Accordion.Header class="acordion-header">SERVICAUCHOS MÉRIDA, C.A.</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faInstagram} /> <a className="contacts" href="https://www.instagram.com/sermeca.ve/" target="_blank">@sermeca</a><br />
                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel:+584247017139" target="_blank">+58 424-7017139</a><br /><br />
                                        <div class="ubic">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3945.1752473280526!2d-71.1687238!3d8.5791428!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e64879ff8689443%3A0xabaac6245b1e70d6!2sSermeca%20C.A!5e0!3m2!1sen!2sve!4v1687876050878!5m2!1sen!2sve" width="400" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="3">
                                    <Accordion.Header class="acordion-header">GRUPO EMPRESARIAL CARRERO MENDEZ, C.A.</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faInstagram} /> <a className="contacts" href="https://www.instagram.com/grupocarreromendez/" target="_blank">@grupocarreromendez</a><br />
                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel:+584147581846" target="_blank">+58 414-7581846</a><br /><br />
                                        <div class="ubic">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d1972.3199891909564!2d-71.66101444718008!3d8.630518255775522!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zOMKwMzcnNTEuMCJOIDcxwrAzOSczOC4wIlc!5e0!3m2!1ses!2sve!4v1687876244841!5m2!1ses!2sve" width="400" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                               

                                <Accordion.Item eventKey="5">
                                    <Accordion.Header class="acordion-header">TOTALLY REBUILT AUTO PARTS, C.A.</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faInstagram} /> <a className="contacts" href="https://www.instagram.com/totallyrebuiltautoparts/" target="_blank">@totallyrebuiltautoparts</a><br />
                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel:+584247554393" target="_blank">+58 424-7554393</a><br /><br />
                                        <div class="ubic">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3944.864634212251!2d-71.64157320000001!3d8.6089927!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e640d8f2e354767%3A0x2bd9c32adea1fd40!2sTOTALLY%20REBUILT%20AUTO%20PARTS.%20C.A.!5e0!3m2!1sen!2sve!4v1687876587166!5m2!1sen!2sve" width="400" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="6">
                                    <Accordion.Header class="acordion-header">INVERSIONES DUARTE BARILLAS, F.P.</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faLocationArrow} />AVENIDA CRISTÓBAL MENDOZA FRENTE AL TERMINAL DE PASAJEROS EDIFICIO GUILLÉN<br />
                                        <FontAwesomeIcon icon={faInstagram} /> SIN INFORMACIÓN<br />
                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel:+584147201037" target="_blank">+58 414-7201037</a><br /><br />
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="7">
                                    <Accordion.Header class="acordion-header">MR CAUCHO, C.A.</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faLocationArrow} />AVENIDA CRISTÓBAL MENDOZA FRENTE AL TERMINAL DE PASAJEROS EDIFICIO GUILLÉN<br />
                                        <FontAwesomeIcon icon={faInstagram} /> <a className="contacts" href="https://www.instagram.com/mrcaucho.ve/" target="_blank">@mrcaucho</a><br />
                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel:+584149728444" target="_blank">+58 414-9728444</a><br /><br />
                                    </Accordion.Body>
                                </Accordion.Item> */}



                            </Accordion>
                            <br /><br /><br />
                        </div>
                    </center>
                </div>
            </body>
            <Footer />
        </>
    );
}

export default Comercio_merida;