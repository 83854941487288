import React from "react";
import Accordion from 'react-bootstrap/Accordion';
import '../commerces/css commerces/Comercio.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faPhone, faLocationArrow } from '@fortawesome/free-solid-svg-icons';
import Preloading from "../sections/Preloading";
import Footer from '../../Footer';

function Comercio_monagas() {
    return (
        <>
            <Preloading />
            <body class="comerce" >
                <div >
                    <center>
                        <div class="acordion-comercio">
                            <br></br>
                            <h2>Redes de Distribución de Monagas</h2>
                            <br></br><br></br>
                            <Accordion style={{ width: '55%' }}>

                                <Accordion.Item eventKey="0">
                                    <Accordion.Header class="acordion-header">DISTRIBUIDORA DE CAUCHOS CALA, C.A.</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faLocationArrow} /> AV. ORINOCO ENTRE CALLE 23 Y 23<br />
                                        <FontAwesomeIcon icon={faInstagram} /> <a className="contacts" href="https://www.instagram.com/cauchoscala/" target="_blank">@cauchoscala</a><br />
                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel:+584249016235" target="_blank">+58 424-9016235</a><br></br><br />
                                        <div class="ubic">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2338.151829931634!2d-63.18870645452559!3d9.741740257956803!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8c33474cef45abcb%3A0x8a1acdf6f4dca159!2sDistribuidora%20De%20Cauchos%20Cala!5e0!3m2!1sen!2sve!4v1685568550314!5m2!1sen!2sve" width="400" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="1">
                                    <Accordion.Header class="acordion-header">IMPORTADORA EL COROZO 2001, C.A.</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faInstagram} /> <a className="contacts" href="https://www.instagram.com/importadoraelcorozo/" target="_blank">@importadoraelcorozo</a><br />
                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel:+584140950548" target="_blank">+58 414-0950548</a><br /><br />
                                        <div class="ubic">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1653.436682924324!2d-63.252960284729454!3d9.718768974501822!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8c33391d2f9ec8f9%3A0xa0d1f9dd8d8887e8!2sImportadora%20El%20Corozo%202001%20C.A!5e0!3m2!1sen!2sve!4v1689257931196!5m2!1sen!2sve" width="400" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                {/* <Accordion.Item eventKey="1">
                                    <Accordion.Header class="acordion-header">INTERCAUCHO GOMEZ, C.A.</Accordion.Header>
                                    <Accordion.Body>
                                        <FontAwesomeIcon icon={faInstagram} /> SIN INFORMACIÓN <br />
                                        <FontAwesomeIcon icon={faPhone} /> <a className="contacts" href="tel:+584145268903" target="_blank">+58 414-5268903</a><br /><br />
                                        <div class="ubic">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3306.463215295237!2d-63.186849344444326!3d9.76017381253343!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8c3340bc3014d869%3A0x11d956e54c68feb!2sIntercaucho%20G%C3%B3mez%20(Covencaucho)!5e0!3m2!1sen!2sve!4v1687875060934!5m2!1sen!2sve" width="400" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item> */}

                            </Accordion><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br />
                        </div>
                    </center>
                </div>
            </body>
            <Footer />
        </>
    );
}

export default Comercio_monagas;