import React from "react";
import './MottoSection.css';
import { Divider } from "@chakra-ui/react";

function MottoSection() {

    return (
        <>
            <section className="lema">
                <div className="word">
                    <p className="frase">Free your way</p>
                </div>
                <br />
            </section>
        </>
    );
}

export default MottoSection;